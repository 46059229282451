@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --themColor:#3D3B40;
  --secondaryColor:#f5b800;
  --darkColor:#3D3B40;
}

body {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div,span{
  font-family: "Tajawal", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active{
  color:var(--secondaryColor);
}

.active-hover :hover{
  color:var(--secondaryColor) !important;
}




.primary-bg-color{
  background-color: var(--themColor)!important;
}
.primary-color{
  color: var(--themColor) !important;
}
.secondary-bg-color{
  background-color: var(--secondaryColor) !important;
}
.secondary-color{
  color: var(--secondaryColor) !important;
}
.menu-border-color{
  border-color: var(--darkColor);
}
/* Butons */
.primary-btn{
  border-width: 1px ;
  border-color: var(--themColor);
  color: var(--themColor);
}
/* .primary-btn :hover{
  border-width: 1px ;
  border-color: var(--darkColor);
  color: var(--darkColor);
  font-weight: bold !important;
} */